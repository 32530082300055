@font-face {
    font-family: 'Stapel';
    src: local('Stapel'), url(./assets/fonts/stapel_regular.woff) format('woff');
}
@font-face {
    font-family: 'Stapel';
    src: local('Stapel'), url(./assets/fonts/stapel_medium.woff) format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'Stapel';
    src: local('Stapel'), url(./assets/fonts/Stapel-Bold.woff) format('woff');
    font-weight: bold;
}
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'), url(./assets/fonts/Manrope-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'), url(./assets/fonts/Manrope-Bold.ttf) format('truetype');
    font-weight: bold;
}